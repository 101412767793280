<template>
  <div class="insurance--wrapper">
    <div class="item_title">
      <div class="item_titleline"></div>
      保险管理
    </div>
    <div class="content">
      <search-form ref="searchForm" @refresh="$refs.table.refresh()" :statusOptions="statusOptions"></search-form>
      <p-table
        ref="table"
        row-key="orderNo"
        :scroll="{ y: 0 }"
        extraHeight="410"
        :sourceData="getDataApi"
        download
        :columns="columns"
        :pagination="false"
      >
        <template slot="headerLeft">
          <div style="display: flex; align-items: center">
            <a-button type="primary" @click="handleAdd('TZFG')" v-perms="'gzxgm'">雇责险购买</a-button>
            <a-button type="primary" @click="handleAdd('TEXC')" v-perms="'tyxgm'">团意险购买</a-button>
          </div>
        </template>
        <template slot="action" slot-scope="text, record">
          <a-button type="link" v-if="record.insuranceStatus === 1" @click="handleStep(record)">电子签章</a-button>
          <a-button type="link" v-if="record.insuranceStatus === 2" @click="handleStep(record)" v-perms="'upload'">上传打款凭证</a-button>
          <a-button type="link" v-if="record.insuranceStatus > 2" @click="handleStep(record)" v-perms="'progress'">订单进度</a-button>
          <a-button type="link" v-if="record.refundUrl" @click="handleOpenPayModal(record)" v-perms="'look'">查看退款凭证</a-button>
          <a-button
            type="link"
            v-if="record.insuranceStatus === 5"
            @click="handleDown(record.orderNo)"
            v-perms="'download-item'"
          >下载保单</a-button
          >
        </template>
      </p-table>
    </div>
    <payModal ref="payModal" />
    <tipModal ref="tipModal" />
  </div>
</template>

<script>
import searchForm from './search-form.vue'
import { getDictListByNames } from '@/api/employmentSupport/dict'
import { getInsuranceList, downloadPDF } from '@/api/insurance'
import payModal from './pay-modal'
import tipModal from './tip-modal.vue'
import { getSalaryContract } from '@/api/recruitUse'

export default {
  name: 'Insurance',
  components: {
    searchForm,
    payModal,
    tipModal
  },
  data () {
    return {
      statusOptions: [],
      columns: [
        {
          title: '序号',
          dataIndex: 'index',
          key: 'index',
          width: 60,
          customRender: (t, r, i) => i + 1,
          download: true
        },
        {
          title: '保险订单号',
          dataIndex: 'orderNo',
          key: 'orderNo',
          download: true
        },
        {
          title: '投保单号',
          dataIndex: 'proposalNo',
          key: 'proposalNo',
          download: true
        },
        {
          title: '保险类型',
          dataIndex: 'proposalType',
          key: 'proposalType',
          customRender: (t, row) => {
            const type = row.proposalNo.substr(0, 4)
            return type === 'TZFG' ? '雇主责任险' : '团体意外险'
          },
          download: true
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          key: 'createTime',
          ellipsis: true,
          download: true
        },
        {
          title: '保险状态',
          dataIndex: 'insuranceStatus',
          key: 'insuranceStatus',
          customRender: (t) => {
            return this.statusOptions.find((item) => item.value == t)?.label
          },
          ellipsis: true,
          download: true
        },
        {
          title: '失败原因',
          dataIndex: 'msg',
          key: 'msg',
          customRender: (t, r) => {
            if ([6, 7].includes(r.insuranceStatus)) {
              return t
            }
            return ''
          },
          ellipsis: true,
          download: true
        },
        {
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ],
      tableData: []
    }
  },
  computed: {
    enterpriseInfo () {
      return this.$store.getters.userInfo?.enterpriseInfoDto
    }
  },
  created () {
    this.initDict()
  },
  methods: {
    initDict () {
      getDictListByNames(['insurance_status']).then((res) => {
        this.statusOptions = res.data[0].detail.sort((a, b) => a.value - b.value)
      })
    },
    getDataApi (params) {
      const form = this.$refs.searchForm.getQuery()
      return getInsuranceList({ pageIndex: 1, pageSize: 10000, ...form, enterpriseNo: this.enterpriseInfo.creditCode })
    },
    handleAdd (type) {
      this.$spin.show()
      getSalaryContract(this.$store.getters.enterpriseId)
        .then((res) => {
          const { YWWB, LLYG, BXJF } = res.data
          if (YWWB || LLYG || BXJF) {
            this.$refs.tipModal.$init(res.data, type)
          } else {
            this.$router.push({
              path: '/insurance/insurance-create',
              query: {
                productFlag: type
              }
            })
          }
        })
        .finally(() => {
          this.$spin.hide()
        })
    },
    handleStep ({ orderNo, proposalNo }) {
      this.$router.push({
        path: '/insurance/insurance-create',
        query: {
          orderNo,
          productFlag: proposalNo.substr(0, 4)
        }
      })
    },
    handleDown (orderNo) {
      this.$spin.show()
      downloadPDF(orderNo)
        .then((res) => {
          window.open(res.data)
        })
        .finally(() => this.$spin.hide())
    },
    handleOpenPayModal (row) {
      this.$refs.payModal.$init(row)
    }
  }
}
</script>

<style lang="less">
@import url('../../../common/style');
.insurance--wrapper {
  .table-btn {
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
  }
}
</style>
