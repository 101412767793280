<template>
  <a-modal
    v-model="visible"
    title="保险功能使用提示"
    width="600px"
    class="tip-modal"
    @cancel="handleClose"
    :footer="false"
  >
    <div class="tip-modal-content">
      <div class="tip">
        使用保险功能，需签订《灵活用工线上签署协议》、《业务外包线上签署协议》、《保费缴款委托书》，请认真阅读后完成下方签章，已签订合同可在控制台-系统设置-企业名片中查看。
      </div>
      <div class="btn-content">
        <div class="btn-item" v-for="item in listData" :key="item.key">
          <div class="btn-item-label">{{ item.label }}</div>
          <a-icon class="btn-item-icon" type="check-circle" style="color: #32d10e" v-if="!datas[item.key]" />
          <div class="btn-item-btn-ac" v-if="!datas[item.key]">已签订</div>
          <div class="btn-item-btn" v-else @click="handleOpen(item.key)">去签订</div>
        </div>
      </div>
    </div>
  </a-modal>
</template>
<script>
import { getSalaryContract } from '@/api/recruitUse'
export default {
  name: 'UploadPayment',
  data () {
    return {
      visible: false,
      listData: [
        {
          label: '业务外包线上签署协议：',
          key: 'YWWB'
        },
        {
          label: '灵活用工线上签署协议：',
          key: 'LLYG'
        },
        {
          label: '保费缴款委托书：',
          key: 'BXJF'
        }
      ],
      datas: {},
      type: '',
      timer: null
    }
  },
  beforeDestroy () {
    this.timer && clearInterval(this.timer)
  },
  methods: {
    $init (datas = {}, type) {
      this.datas = datas
      this.type = type
      this.visible = true
      this.timer = setInterval(() => {
        this.refreshStatus()
      }, 2000)
    },
    refreshStatus () {
      getSalaryContract(this.$store.getters.enterpriseId).then((res) => {
        this.datas = res.data
        const { YWWB, LLYG, BXJF } = res.data
        if (!YWWB && !LLYG && !BXJF) {
          this.timer && clearInterval(this.timer)
          this.$confirm({
            title: '提示',
            content: `第三方协议签署完成，是否立即进行跳转至【${this.type == 'TZFG' ? '雇责险购买' : '团意险购买'}】`,
            onOk: () => {
              this.$router.push({
                path: '/insurance/insurance-create',
                query: {
                  productFlag: this.type
                }
              })
            }
          })
        }
      })
    },
    handleClose () {
      this.timer && clearInterval(this.timer)
    },
    handleOpen (key) {
      window.open(this.datas[key])
    }
  }
}
</script>

<style lang="less">
.tip-modal {
  .btn-content {
    .btn-item {
      margin-top: 20px;
      display: flex;
      position: relative;
      align-items: center;
      justify-content: space-between;
      .btn-item-btn {
        cursor: pointer;
        color: #1890ff;
      }
      .btn-item-btn-ac {
        color: #32d10e;
      }
      .btn-item-icon {
        position: absolute;
        right: 50px;
      }
    }
  }
}
</style>
